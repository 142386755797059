import sovesveisImgOne from "../../../img/portfolio/sovesveis/sovesveis-optimized.jpg";
import sovesveisImgTwo from "../../../img/portfolio/sovesveis/sovesveis-2-optimized.jpg";
import sovesveisImgThree from "../../../img/portfolio/sovesveis/sovesveis-3-optimized.jpg";
import sovesveisImgFour from "../../../img/portfolio/sovesveis/sovesveis-4-optimized.jpg";
import sovesveisImgSix from "../../../img/portfolio/sovesveis/sovesveis-6-optimized.jpg";
import sovesveisImgSeven from "../../../img/portfolio/sovesveis/sovesveis-7-optimized.jpg";
import sovesveisImgEight from "../../../img/portfolio/sovesveis/sovesveis-8-optimized.jpg";

const sovesveisImagesArray = [
    sovesveisImgOne,
    sovesveisImgTwo,
    sovesveisImgThree,
    sovesveisImgFour,
    sovesveisImgSix,
    sovesveisImgSeven,
    sovesveisImgEight
  ]
  
  export default sovesveisImagesArray;