import torungenImgOne from "../../../img/portfolio/torungen/torungen-optimized.jpg";
import torungenImgTwo from "../../../img/portfolio/torungen/torungen-2-optimized.jpg";
import torungenImgThree from "../../../img/portfolio/torungen/torungen-3-optimized.jpg";
import torungenImgFour from "../../../img/portfolio/torungen/torungen-4-optimized.jpg";
import torungenImgFive from "../../../img/portfolio/torungen/torungen-5-optimized.jpg";
import torungenImgSix from "../../../img/portfolio/torungen/torungen-6-optimized.jpg";
import torungenImgSeven from "../../../img/portfolio/torungen/torungen-7-optimized.jpg";




const torungenImagesArray = [
   torungenImgSeven,
   torungenImgSix,
   torungenImgOne,
   torungenImgTwo,
   torungenImgThree,
   torungenImgFour,
   torungenImgFive,

 
  
]

export default torungenImagesArray;