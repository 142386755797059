import bergillImgOne from "../../../img/portfolio/bergill/bergill-optimized.jpg";
import bergillImgTwo from "../../../img/portfolio/bergill/bergill1-optimized.jpg";
import bergillImgThree from "../../../img/portfolio/bergill/bergill2-optimized.jpg";
import bergillImgFour from "../../../img/portfolio/bergill/bergill3-optimized.jpg";

const bergillImagesArray = [
    bergillImgOne,
    bergillImgTwo,
    bergillImgThree,
    bergillImgFour
]

export default bergillImagesArray ;