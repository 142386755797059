import ambijusImgOne from "../../../img/portfolio/ambijus/ambijus-optimized.jpg"
import ambijusImgTwo from "../../../img/portfolio/ambijus/ambijus-2-optimized.jpg"
import ambijusImgThree from "../../../img/portfolio/ambijus/ambijus-3-optimized.jpg"
import ambijusImgFour from "../../../img/portfolio/ambijus/ambijus-4-optimized.jpg"
import ambijusImgFive from "../../../img/portfolio/ambijus/ambijus-5-optimized.jpg"
import ambijusImgSix from "../../../img/portfolio/ambijus/ambijus-6-optimized.jpg"
import ambijusImgSeven from "../../../img/portfolio/ambijus/ambiju-7-optimized.jpg"
import ambijusImgEight from "../../../img/portfolio/ambijus/ambijus-8-optimized.jpg"


const ambijusImagesArray = [
    ambijusImgOne,
    ambijusImgTwo,
    ambijusImgThree,
    ambijusImgFour,
    ambijusImgFive,
    ambijusImgSix,
    ambijusImgSeven,
    ambijusImgEight
  
]

export default ambijusImagesArray;