import winebarImgOne from "../../../img/portfolio/vinbaren/vinbaren-optimized.jpg";
import winebarImgTwo from "../../../img/portfolio/vinbaren/vinbaren-2-optimized.jpg";
import winebarImgThree from "../../../img/portfolio/vinbaren/vinbaren-3-optimized.jpg";
import winebarImgFour from "../../../img/portfolio/vinbaren/vinbaren-4-optimized.jpg";
import winebarImgFive from "../../../img/portfolio/vinbaren/vinbaren-5-optimized.jpg";


const winebarMollaImagesArray = [
    winebarImgOne,
    winebarImgTwo,
    winebarImgThree,
    winebarImgFour,
    winebarImgFive
  
]

export default winebarMollaImagesArray;