import wkImgOne from "../../../img/portfolio/wilder-kitchen/wk-1-optimized.jpg";
import wkImgTwo from "../../../img/portfolio/wilder-kitchen/wk-2-optimized.jpg";
import wkImgThree from "../../../img/portfolio/wilder-kitchen/wk-3-optimized.jpg";
import wkImgFour from "../../../img/portfolio/wilder-kitchen/wk-4-optimized.jpg";
import wkImgFive from "../../../img/portfolio/wilder-kitchen/wk-5-optimized.jpg";
import wkImgSix from "../../../img/portfolio/wilder-kitchen/wk-6-optimized.jpg";
import wkImgSeven from "../../../img/portfolio/wilder-kitchen/wk-7-optimized.jpg"

const wilderImagesArray = [
  wkImgOne,
  wkImgTwo,
  wkImgThree,
  wkImgFour,
  wkImgFive,
  wkImgSix,
  wkImgSeven
]

export default wilderImagesArray;